.spinner {
  width: 40px;
  height: 40px;
  -webkit-mask-composite: destination-in;
  background: #1e87f0;
  border-radius: 50%;
  animation: .8s infinite spinner-mv2oco;
  -webkit-mask-image: repeating-conic-gradient(#0000 0deg, #000 1deg 70deg, #0000 71deg 90deg), radial-gradient(farthest-side, #0000 calc(100% - 7.2px), #000 calc(100% - 6.4px));
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: border-box, border-box;
  -webkit-mask-origin: border-box, border-box;
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

@keyframes spinner-mv2oco {
  to {
    transform: rotate(.5turn);
  }
}

/*# sourceMappingURL=index.e4ba3e88.css.map */

.spinner {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background: #1e87f0;
   -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg 70deg,#0000 71deg 90deg),
        radial-gradient(farthest-side,#0000 calc(100% - 7.2px),#000 calc(100% - 6.4px));
   -webkit-mask-composite: destination-in;
   mask-composite: intersect;
   animation: spinner-mv2oco 0.8s infinite;
}

@keyframes spinner-mv2oco {
   to {
      transform: rotate(.5turn);
   }
}